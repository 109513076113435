import React from "react";

const ServicesPage = () => {
  return <div>
    {/* Shaves and beard: hot towel and straight razor
    Haircut
Signature haircut which includes both shears and clipper work to fit your preference. Includes hair wash, razor neck shave and style.

Beard trim
Well groomed look that includes hot towels, hot lather, razor shave, beard oil. Also, adding a massage to face, head and shoulders to elevate your experience.

HEAD SHAVE
Performed with a straight razor that includes hot lather, hot towels, a straight razor neck shave, after shave and moisturizer.

Beard shave
Precision clean shave, ensuring a polished and refreshed appearance. Includes hot lather, straight razor shave and moisturizer with massage.

KIDS HAIRCUT

Treat your little ones to a delightful and expertly crafted Kids Haircut service. Haircut designs available. */}
  </div>;
};

export default ServicesPage;
